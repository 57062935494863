import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import SEO from "../components/seo"
import Header from "../components/MainComponents/header"
import Footer from "../components/footer"


const GlobalStyle = createGlobalStyle`
  :root {
    --logo-color: #323593;
    --first-color: #555b6e;
    --second-color: #89b0ae;
    --third-color: #bee3db;
    --fourth-color: #ffd6ba;
    --fifth-color: #faf9f9;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    border: 0;
  }
`

const Layout = styled.div`
  box-sizing: border-box;
  transition: transform 1s;
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  
  & > div:not(:first-child) {
    min-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    
    @media screen and (max-width: 768px) {
      min-width: 360px;
    }
  }
`;

const MainLayout = ({children}) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <GlobalStyle />
      <SEO title="Home" />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Layout id="main-layout">
        {children}
        <Footer />
      </Layout>
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
