import React from 'react';
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"

const FifthContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background-color: var(--first-color);
  overflow: hidden;
`;

const CompanyListContainer = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 150px);
  grid-template-rows: repeat(4, 150px);
  grid-gap: 10px;
  width: 620px;
  font-size: 18px;
  line-height: 150px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    font-size: 13px;
    width: 320px;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 100px);
    line-height: 100px;
    margin: 0 auto;
  }
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  opacity: 1;
  position: absolute !important;
  top: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity .3s;
  
  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`

const CompanyText = styled.div`
  position: relative;
  z-index: 2;
  opacity: 0;
  font-weight: bold;
  transition: opacity .3s;
`

const CompanyLink = styled(Link)`
  display: block;
  text-decoration: none;
  background-color: white;
  color: #000000;
  transition: transform .8s .5s;
  position: relative;
  transform: ${props => props.show ? ``: 'rotate3d(0, 1, 0, 450deg)'};
  
  :hover {
    ${ImageContainer} {
      opacity: 0.2;
    }
    
    ${CompanyText} {
      opacity: 1;
    }
  }
  
  @media screen and (max-width: 768px) {
    ${ImageContainer} {
      opacity: 0.2;
    }
    ${CompanyText} {
      opacity: 1;
    }
    
    :last-child {
      display: none;
    }
  }
`;

const DefaultBox = styled.div`
  background-color: var(--third-color);
  font-weight: bold;
  color: black;
  transition: transform .8s .5s;
  transform: ${props => props.show ? ``: 'rotate3d(0, 1, 0, 450deg)'};
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 35px;
  line-height: 35px;
  font-weight: bold;
  width: 960px;
  margin: 0 auto 30px;
  position: relative;
  z-index: 3;
  
  @media screen and (max-width: 768px) {
    width: 360px;
    font-size: 30px;
    text-align: center;
  }
`;

const TitleBackground = styled.div`
  width: ${props => props.show ? '855px' : 0};
  height: 15px;
  background-color: var(--second-color);
  bottom: 0;
  left: 105px;
  position: absolute;
  z-index: -1;
  transition-delay: 1s;
  transition: width 1s ease-in-out;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Image = styled(GatsbyImage)`
  & img {
    object-fit: contain !important; // or whatever
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;' // needed for IE9+ polyfill
  }
  
  @media screen and (max-width: 768px) {
    width: 100px !important;
    height: 100px !important;
    & img {
      object-fit: contain !important;
      width: 100px !important;
      height: 100px !important;
    }
  }
`;

function FifthComponent ({isStartAnimation}) {

  const data = useStaticQuery(graphql`
      query {
          allCompanyListJson {
              edges {
                  node {
                      name
                      link
                      image
                  }
              }
          }
          airImage: file(relativePath: { eq: "main_page_air.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          guzaeImage: file(relativePath: { eq: "main_page_guzae.png" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          mountainImage: file(relativePath: { eq: "main_page_mountain.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          nowonImage: file(relativePath: { eq: "main_page_nowon.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          railImage: file(relativePath: { eq: "main_page_rail.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          songpaImage: file(relativePath: { eq: "main_page_songpa.png" }) {
              childImageSharp {
                  fixed(height: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          toImage: file(relativePath: { eq: "main_page_to.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          waterImage: file(relativePath: { eq: "main_page_water.jpg" }) {
              childImageSharp {
                  fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          zongloImage: file(relativePath: { eq: "main_page_zonglo.jpg" }) {
              childImageSharp {
                  fixed(height: 150) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)


  return (
    <FifthContainer>
      <Title>
        우리와 함께한 분들
        <TitleBackground show={isStartAnimation} />
      </Title>
      <CompanyListContainer>
        {data.allCompanyListJson.edges.map(({ node }, index) =>
          node.link !== null ? (
            <CompanyLink
              show={isStartAnimation}
              key={index}
              to={`/technology/performance/${node.link}`}>
              <CompanyText>{node.name}</CompanyText>
              <ImageContainer>
                {data[node.image] &&
                  <Image fixed={data[node.image].childImageSharp.fixed} />
                }
              </ImageContainer>
            </CompanyLink>
          ) : (
            <DefaultBox
              show={isStartAnimation}
              key={index}>
              {node.name}
            </DefaultBox>
          )
        )}
        <CompanyLink
          show={isStartAnimation}
          style={{
            fontWeight: 'bold',
            backgroundColor: 'var(--third-color)'
          }}
          to="/technology/performance">
          + 더보기
        </CompanyLink>
      </CompanyListContainer>
    </FifthContainer>
  )
}

export default FifthComponent