import React  from "react"
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby"
import "./SecondComponent.css";

const SecondContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background-color: var(--logo-color);
`;

const TitleContainer = styled.div`
  position: relative;
  color: white;
  font-size: 50px;
  z-index: 3;
  width: 770px;
  height: 50px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  
  
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 20px;
    height: 20px;
  }
`;

const Title = styled.div`
  opacity: 0;
  transition: transform .5s, opacity .5s;
  
  ${props => props.show &&
    css`
      opacity: 1;
      transform: translateY(-30px);
    `
  };
  
  @media screen and (max-width: 768px) {
    opacity: 1;
  }
`;


const Number = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: bold;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 30px;
    padding-top: 25px;
    margin-bottom: 5px;
  }
`;

const Description = styled.div`
  color: white;
  text-align: center;
  height: 40px;
  opacity: 0;
  transition: transform .5s, opacity .5s;
  
  @media screen and (max-width: 768px) {
    position: relative;
    height: 100px;
    width: 100px;
    
    ::before {
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border: 2px solid var(--third-color);
      border-radius: 100%;
      transform: scale(1.7);
    }
    :first-child {
      top: 125px;
      left: 60px;
    }
    :last-child {
      top: 125px;
      right: 60px;
    }
  }
`

const DescriptionsContainer = styled.div`
  width: 770px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  ${Description} {
    ${props => props.show &&
      css`
        opacity: 1;
        transform: translateY(20px);
      `
    };
    
    @media screen and (max-width: 768px) {
      opacity: 1;
    }
  };
  
  @media screen and (max-width: 768px) {
    width: 360px;
    height: 300px;
    padding-top: 40px;
  }
`;

const Graph = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1.3s;
`;

const Line = styled.path.attrs({
  stroke: "#FFFFFF",
  strokeWidth: 4,
  fillOpacity: 0,
  strokeDasharray: 10
})`
  transform: scaleY(0);
  transform-origin: center bottom;
  animation: draw .5s linear forwards;
  animation-delay: 2.31s;
`;

const Ayle = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 0.3s linear forwards;
  animation-delay: 1s;
`;

const GraphContainer = styled.div`
  width: 900px;
  margin: 0 auto;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

function SecondComponent ({isStartAnimation}) {
  const {
    allPerformanceListJson: {
      edges: performance
    },
    allPatentListJson: {
      edges: patent
    }
  } = useStaticQuery(graphql`
    query {
        allPerformanceListJson {
            edges {
                node {
                    id
                }
            }
        }
        allPatentListJson {
            edges {
                node {
                    id
                }
            }
        }
    }
  `)

  return (
    <SecondContainer>
      <TitleContainer>
        <Title show={isStartAnimation}>
          "최고의 안전을 위한 더나은 기술"
        </Title>
      </TitleContainer>

      {
        isStartAnimation &&
        <GraphContainer>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="900"
            height="400"
            viewBox="0 0 900 400">
            <Ayle d="M 0,400 L 0,0" stroke="var(--third-color)" strokeWidth="5" fillOpacity="0" />
            <Ayle d="M 0,400 L 900,400" stroke="var(--third-color)" strokeWidth="5" fillOpacity="0" />

            <Line d="M 123,100 L 123,400" />
            <Line d="M 470,300 L 470,400" />
            <Line d="M 797,200 L 797,400" />

            <Graph d="M 123,100 L 470,300 L 797,200" stroke="var(--third-color)" strokeWidth="7" fillOpacity="0" />

            <circle className="dot" cx="123" cy="100" r="10" fill="white" />
            <circle className="dot" cx="470" cy="300" r="10" fill="white" />
            <circle className="dot" cx="797" cy="200" r="10" fill="white" />
          </svg>
        </GraphContainer>
      }
      <DescriptionsContainer show={isStartAnimation}>
        <Description>
          <Number>
            1986
          </Number>
          설립 연도
        </Description>
        <Description>
          <Number>
            {patent.length}
          </Number>
          관련 특허
        </Description>
        <Description>
          <Number>
            {performance.length}
          </Number>
          시공 개수
        </Description>
      </DescriptionsContainer>
    </SecondContainer>
  )
}


export default SecondComponent