import React, { useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import './FirstComponent.css';

const FirstContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: var(--logo-color);
  font-family: "BMDOHYEON", sans-serif;
`;
const ImageContainer = styled.div`
  width: 60%;
  height: 100%;
  z-index: 2;
  position: absolute;
  right: 0;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Image = styled(Img)`
  height: 100%;
`;

const TitleContainer = styled.div`
  color: white;
  position: absolute;
  z-index: 3;
  margin-left: 24%;
  margin-top: 38vh;
  transform: ${props => props.show ? css`translateY(0)` : css`translateY(100px)`};
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity .5s ease .3s, transform .5s ease .3s;
  
  @media screen and (max-width: 768px) {
    width: calc(80%);
    margin-left: 10%;
  }
`;

const Title = styled.div`
  font-size: 100px;
  font-weight: bold;
  line-height: 100px;
  margin-top: 20px;
  
  @media screen and (max-width: 768px) {
    font-size: 45px;
    line-height: 45px;
  }
`;

const SubTitle = styled.div`
  font-size: 35px;
  line-height: 35px;
  margin-top: 30px;
  
  
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
    width: 100%;
    word-break: break-word;
  }
`

const Year = styled.div`
  font-size: 25px;
  line-height: 25px;
  
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const NowYear = styled.div`
  color: white;
  font-size: 200px;
  line-height: 100vh;
  text-align: center;
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: ${props => props.show ? 0 : 1};
  transition: opacity .3s ease;
  
  @media screen and (max-width: 768px) {
    font-size: 100px;
  }
`;


function FirstComponent () {
  const [show, setShow] = useState(false);
  const imageData = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: { eq: "main-page-image.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  useLayoutEffect(() => {
    function decrease (nowYear, firstYear, destination) {
      const id = document.getElementById('now-year');
      if (nowYear === destination) {
        setTimeout(() => {
          setShow(true)
        }, 1000)
        return;
      }
      setTimeout(() => {
        if(id) {
          id.innerText = String(nowYear - 1);
          decrease(nowYear - 1, firstYear, destination)
        }
      }, 7 * (firstYear - nowYear))
    }
    const nowYear = new Date().getFullYear()
    setTimeout(() => {
      decrease(nowYear, nowYear, 1986)
    }, 1000)

    return () => {
      clearTimeout()
    }
  }, [])

  return (
    <FirstContainer>
      <NowYear id="now-year" show={show}>{new Date().getFullYear()}</NowYear>
      <TitleContainer show={show}>
        <Year>SINCE 1986</Year>
        <Title>동아특수건설</Title>
        <SubTitle>최고의 안전을 위한 더나은 기술</SubTitle>
      </TitleContainer>
      <ImageContainer>
        <Image
          className="mainImage"
          fluid={imageData.placeholderImage.childImageSharp.fluid} />
      </ImageContainer>
    </FirstContainer>
  )
}


export default FirstComponent
