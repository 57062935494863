import React from 'react';
import styled from "styled-components";
import Email from '../../assets/svg/email.svg';
import Phone from '../../assets/svg/phone.svg';
import { graphql, useStaticQuery } from "gatsby"

const LastContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 250px);
  position: relative;
  background-color: var(--second-color);
  padding: 130px 240px;
  color: black;
  
  @media screen and (max-width: 768px) {
    padding: 20px 10px;
    height: 300px;
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 60px;
  width: 140px;
  border-right: 5px black solid;
  
  @media screen and (max-width: 768px) {
    padding: 0;
    width: 80px;
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled.div`
  z-index: 3;
  margin-bottom: 110px;
  font-size: 30px;
  line-height: 30px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 20px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Contact = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  
  @media screen and (max-width: 768px) {
    height: 50px;
    width: 300px;
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    flex-direction: row;
    justify-content: space-around;
    
    & > br {
      display: none;
    }
  }
`;

const ContactTitle = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const EmailIcon = styled(Email)`
  margin-bottom: 40px;
  fill: black;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    width: 40px;
    height: 40px;
  }
`;

const PhoneIcon = styled(Phone)`
  margin-bottom: 40px;
  fill: black;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    width: 40px;
    height: 40px;
  }
`;

function LastComponent () {
  const contactData = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  email
                  phone
              }
          }
      }
  `)
  return (
    <LastContainer>
      <TitleContainer>
        <Title>연락처</Title>
        <SubTitle>템플릿을 원하시면 아래로 연락바랍니다</SubTitle>
      </TitleContainer>
      <ContactContainer>
        <Contact>
          <EmailIcon />
          <ContactTitle>
            이메일
          </ContactTitle>
          <br/>
          {contactData.site.siteMetadata.email}
        </Contact>
        <Contact>
          <PhoneIcon />
          <ContactTitle>
            문의전화
          </ContactTitle>
          <br/>
          {contactData.site.siteMetadata.phone}
        </Contact>
      </ContactContainer>
    </LastContainer>
  )
}

export default LastComponent