import React, { useLayoutEffect, useState } from "react"
import { createGlobalStyle } from "styled-components"

import MainLayout from "../container/MainLayout"

import FirstComponent from "../components/MainComponents/FirstComponent.js"
import SecondComponent from "../components/MainComponents/SecondComponent.js"
import ThirdComponent from "../components/MainComponents/ThirdComponent"
import FourthComponent from "../components/MainComponents/FourthComponent.js"
import FifthComponent from "../components/MainComponents/FifthComponent"
import LastComponent from "../components/MainComponents/LastComponent"
import "./index.css"

const GlobalStyle = createGlobalStyle`
  @font-face { 
    font-family: 'BMDOHYEON'; 
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff') format('woff');
    font-weight: normal; 
    font-style: normal;
  } 
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
  }
  body {
    overflow: hidden;
  }
  
  html::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  
  html {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  
  @media screen and (max-width: 768px) {
    html {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
`

const Index = () => {

  const [isStartAnimation, setIsStartAnimation] = useState(0)

  useLayoutEffect(() => {
    const main = document.getElementById('main-layout');

    function makeScrollCallback() {
      const isBrowser = typeof window !== `undefined`
      if(!isBrowser) return
      let tick = false

      return function onWheel() {
        if(tick)
          return

        tick = true
        return requestAnimationFrame(() => {
          const main = document.getElementById('main-layout');
          if (window.innerWidth <= 786) {
            let vh = window.innerHeight / 100
            if (390 * vh <= window.pageYOffset) {
              setIsStartAnimation(3)
              tick = true
            }
            else {
              tick = false
            }
            return
          }
          if (400 <= Math.abs(parseInt(main.style.transform.replace('translateY(', '')))) {
            setIsStartAnimation(3)
            tick = true
          } else if (200 <= Math.abs(parseInt(main.style.transform.replace('translateY(', '')))) {
            setIsStartAnimation(2)
            tick = false
          }
          else if(100 <= Math.abs(parseInt(main.style.transform.replace('translateY(', '')))) {
            setIsStartAnimation(1)
            tick = false
          }
          else {
            tick = false
          }
        })
      }
    }
    function makeMobileScrollCallback() {
      const isBrowser = typeof window !== `undefined`
      if(!isBrowser) return
      let tick = false

      return function onScroll() {
        if(tick)
          return

        tick = true
        return requestAnimationFrame(() => {
          let vh = window.innerHeight / 100
          if (375 * vh <= window.pageYOffset) {
            setIsStartAnimation(3)
            tick = true
          }
          else {
            tick = false
          }
        })
      }
    }

    main.addEventListener('wheel', makeScrollCallback())
    document.addEventListener('scroll', makeMobileScrollCallback())
    return () => {
      main.removeEventListener('wheel', makeScrollCallback())
      document.removeEventListener('scroll', makeMobileScrollCallback())
    }
  }, [])

  useLayoutEffect(() => {
    function makeWheelEvent() {
      let scdir, hold = false, pnls = 6;
      function _scrollY(obj) {
        let slength, plength, pan, step = 100,
          vh = window.innerHeight / 100
        if ((this !== undefined && this.id === 'main-layout') ||
          (obj !== undefined && obj.id === 'main-layout')) {
          pan = this || obj;
          plength = parseInt(pan.offsetHeight / vh);
        }
        if (pan === undefined) {
          return;
        }
        slength = parseInt(pan.style.transform.replace('translateY(', ''));
        if (scdir === 'up' && Math.abs(slength) < (plength - plength / pnls)) {
          slength = slength - step;
        } else if (scdir === 'down' && slength < 0) {
          slength = slength + step;
        }
        if (hold === false) {
          hold = true;
          if(window.innerWidth > 786)
            pan.style.transform = 'translateY(' + slength + 'vh)';
          else {
            pan.style.transform = 'translateY(0)';
            hold = true;
            return
          }
          setTimeout(function() {
            hold = false;
          }, 1000);
        }
      }
      /*[assignments]*/
      let main = document.getElementById('main-layout');
      main.style.transform = 'translateY(0)';
      main.addEventListener('wheel', function(e) {
        if (e.deltaY < 0) {
          scdir = 'down';
        }
        if (e.deltaY > 0) {
          scdir = 'up';
        }
      });
      main.addEventListener('wheel', _scrollY);
    }
    makeWheelEvent()
  }, []);

  return (
    <MainLayout>
      <GlobalStyle />
      <FirstComponent />
      <SecondComponent isStartAnimation={isStartAnimation >= 1} />
      <ThirdComponent isStartAnimation={isStartAnimation >= 2} />
      <FourthComponent />
      <FifthComponent isStartAnimation={isStartAnimation === 3} />
      <LastComponent />
    </MainLayout>
  )
}

export default Index
