import React from 'react';
import styled from "styled-components";
import Way from "../../assets/svg/way.svg";

const FourthContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background-color: var(--fourth-color);
`;

const VideoContainer = styled.div`
  position: relative;
  width: 960px;
  margin: auto;
  z-index: 3;
  
  @media screen and (max-width: 768px) {
  margin: 0;
    width: 360px;
  }
`;


const Title = styled.div`
  color: black;
  font-size: 35px;
  font-weight: bold;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`;


const Youtube = styled.iframe`
  width: 960px;
  height: 540px;
  margin-top: 70px;
  
  @media screen and (max-width: 768px) {
    width: 360px;
    height: 240px;
  }
`;

const WayBackground = styled(Way)`
  bottom: 0;
  right: 0;
  position: absolute;
  fill: var(--fifth-color);
  
  @media screen and (max-width: 768px) {
    width: 360px;
    height: auto;
  }
`


function FourthComponent () {

  return (
    <FourthContainer>
      <VideoContainer>
        <Title>우리가 걸어온 길</Title>
        <Youtube
          title="Introduce Video"
          src="https://www.youtube.com/embed/IDmzu839O40"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen />
      </VideoContainer>
      <WayBackground />
    </FourthContainer>
  )
}


export default FourthComponent