import React, { useState } from "react"
import styled, { css } from "styled-components";
import Cable from "../../assets/svg/tech_cable.svg";
import Samun from "../../assets/svg/tech_samun.svg";
import Mountain from "../../assets/svg/tech_mountain.svg";
import Zulto from "../../assets/svg/tech_zulto.svg";
import Tie from "../../assets/svg/tech_tie.svg";
import Soil from "../../assets/svg/tech_soil.svg";
import Connect from "../../assets/svg/tech_connect.svg";
import Close from "../../assets/svg/close.svg";
import './ThirdComponent.css';
import { Link, graphql, useStaticQuery } from "gatsby"
import { CSSTransition } from "react-transition-group"

const ThirdContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 100px 0 0;
  display: block;
  position: relative;
  overflow: hidden;
  color: var(--fifth-color);
`;

const Title = styled.div`
  width: 960px;
  margin: 0 auto;
  color: black;
  font-size: 35px;
  font-weight: bold;
  height: 40px;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 1500px;
  margin: 0 auto;
  height: 800px;
  position: relative;
  
  @media screen and (max-width: 768px) {
    width: 360px;
    height: 500px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 800px;
  position: absolute;
  
  @media screen and (max-width: 768px) {
    height: 500px;
  }
`

const SVGContainer = styled.div`
  width: 160px;
  height: 160px;
  background-color: var(--first-color);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: transform .5s ease-in-out;
  transform: ${props => props.selected ? css`translate(670px, 250px) scale(2)` : props.transform};
  z-index: ${props => props.selected ? 3 : 1};
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  overflow: hidden;
  
  & > svg {
    width: 100%;
    height: 100%;
  }
  
  @media screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
    transition: transform .3s ease-in-out;
    transform: ${props => props.selected ? css`translate(10px, 20px)` : props.mobileTransform};
  }
`;

const LineContainer = styled.svg`
  position: absolute;
  width: 100%;
  height: 800px;
  z-index: -1;
  top: 40px;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Background = styled.div`
  background: white;
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: -1;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity .3s;
    z-index: 2;
  }
  &.fade-enter-done {
    z-index: 2;
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
    z-index: 2;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity .3s;
  }
  &.fade-exit {
    z-index: -1;
  }
  
  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

const DetailLeftContainer = styled.div`
  color: var(--first-color);
  height: 400px;
  display: inline-block;
  position: absolute;
  top: 130px;
  left: 400px;
  .fade-enter & {
    transform: translateX(0);
  }
  .fade-enter-active & {
    transition: transform .5s ease-in-out .5s;
    transform: translateX(-300px);
  }
  .fade-enter-done & {
    transform: translateX(-300px);
  }
  .fade-exit & {
    transform: translateX(-300px);
  }
  .fade-exit-active & {
    transition: transform .1s;
    transform: translateX(0);
  }
  .fade-exit-done & {
    transform: translateX(0);
  }
  ::before {
    display: inline-block;
    font-size: 400px;
    height: 400px;
    line-height: 400px;
    content: '{';
  }
  
  @media screen and (max-width: 768px) {
    opacity: 0;
  }
`

const DetailRightContainer = styled.div`
  color: var(--first-color);
  height: 400px;
  display: inline-block;
  position: absolute;
  top: 130px;
  left: 920px;
  .fade-enter & {
    transform: translateX(0);
  }
  .fade-enter-active & {
    transition: transform .5s ease-in-out .5s;
    transform: translateX(300px);
  }
  .fade-enter-done & {
    transform: translateX(300px);
  }
  .fade-exit & {
    transform: translateX(300px);
  }
  .fade-exit-active & {
    transition: transform .1s;
    transform: translateX(0);
  }
  .fade-exit-done & {
    transform: translateX(0);
  }
  ::before {
    display: inline-block;
    font-size: 400px;
    height: 400px;
    line-height: 400px;
    content: '}';
  }
  
  @media screen and (max-width: 768px) {
    opacity: 0;
  }
`;

const DetailContent = styled.div`
  position: absolute;
  top: 220px;
  left: 250px;
  height: 300px;
  width: 300px;
  .fade-enter & {
    opacity: 0;
  }
  .fade-enter-active & {
    transition: opacity .1s ease-in-out 1s;
    opacity: 1;
  }
  .fade-enter-done & {
    opacity: 1;
  }
  .fade-exit & {
    opacity: 1;
  }
  .fade-exit-active & {
    transition: opacity .05s;
    opacity: 0;
  }
  .fade-exit-done & {
    opacity: 0;
  }
  
  @media screen and (max-width: 768px) {
    top: 100px;
    left: 0;
    
    .fade-enter-active & {
      transition: opacity .1s ease-in-out .3s;
      opacity: 1;
    }
  }
`;

const DetailTitle = styled.h3`
  font-family: "NanumSquare", georgia, serif;
  font-size: 28px;
  padding: 10px;
  font-weight: bold;
  background-color: var(--first-color);
  color: white;
  display: inline-block;
  margin: 0;
  
  @media screen and (max-width: 768px) {
    font-size: 27px;
    padding: 3px 8px;
    margin-top: 10px;
    margin-left: 15px;
  }
`;
const Detail = styled.div`
  margin-top: 20px;
  font-size: 20px;
  color: black;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 18px;
    margin-left: 15px;
    word-break: break-word;
  }
`;

const DetailButtonContainer = styled.div`
  position: absolute;
  top: 220px;
  left: 970px;
  height: 300px;
  width: 300px;
  .fade-enter & {
    opacity: 0;
  }
  .fade-enter-active & {
    transition: opacity .1s ease-in-out 1s;
    opacity: 1;
  }
  .fade-enter-done & {
    opacity: 1;
  }
  .fade-exit & {
    opacity: 1;
  }
  .fade-exit-active & {
    transition: opacity .05s;
    opacity: 0;
  }
  .fade-exit-done & {
    opacity: 0;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 18px;
    top: 30px;
    left: 120px;
    word-break: break-word;
    
    .fade-enter-active & {
      transition: opacity .1s ease-in-out .3s;
      opacity: 1;
    }
  }
`;

const DetailButton = styled(Link)`
  border: 0;
  background-color: var(--first-color);
  border-radius: 3px;
  color: white;
  font-size: 25px;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  
  :hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    padding: 3px 8px;
    word-break: break-word;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
    right: 20px;
    
    & > svg {
      width: 50px;
      height: 50px;
    }
  }
`;

const Line = styled.path.attrs({
  strokeWidth: 4,
  fillOpacity: 0
})`
  transform-origin: center bottom;
  animation: dash .5s linear forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation-delay: 1s;
  stroke: var(--first-color);
`;

function ThirdComponent ({isStartAnimation}) {
  const [index, setIndex]= useState(-1);
  const [name, setName]= useState('');
  const [description, setDescription]= useState('');

  const {
    allTechnologyJson : {
      edges: technologyList
    }
  } = useStaticQuery(graphql`
      query {
          allTechnologyJson {
              edges {
                  node {
                      type
                      description
                  }
              }
          }
      }
  `)

  const handleSVGContainer = (nowIndex) => () => {
    if(index === -1) {
      let technology = technologyList.find((value, listIndex) => nowIndex === listIndex + 1)
      setIndex(nowIndex)
      setName(technology.node.type)
      setDescription(technology.node.description)
    } else {
      setIndex(-1)
    }
  }

  return (
    <ThirdContainer>
      <Title>우리의 기술</Title>
      <ContentContainer>
        <Content>
          <SVGContainer
            selected={index === 1}
            onClick={handleSVGContainer(1)}
            transform="translate(670px, 150px)"
            mobileTransform="translate(70px, 48px)">
            <Zulto />
          </SVGContainer>
          <SVGContainer
            selected={index === 2}
            onClick={handleSVGContainer(2)}
            transform="translate(700px, 550px)"
            mobileTransform="translate(210px, 48px)">
            <Samun />
          </SVGContainer>
          <SVGContainer
            selected={index === 3}
            onClick={handleSVGContainer(3)}
            transform="translate(950px, 340px)"
            mobileTransform="translate(280px, 190px)">
            <Mountain />
          </SVGContainer>
          <SVGContainer
            selected={index === 4}
            onClick={handleSVGContainer(4)}
            transform="translate(400px, 350px)"
            mobileTransform="translate(70px, 342px)">
            <Tie />
          </SVGContainer>
          <SVGContainer
            selected={index === 5}
            onClick={handleSVGContainer(5)}
            transform="translate(1300px, 250px)"
            mobileTransform="translate(210px, 342px)">
            <Cable />
          </SVGContainer>
          <SVGContainer
            selected={index === 6}
            onClick={handleSVGContainer(6)}
            transform="translate(100px, 490px)"
            mobileTransform="translate(0, 190px)">
            <Soil />
          </SVGContainer>
          <SVGContainer
            selected={index === 7}
            onClick={handleSVGContainer(7)}
            transform="translate(1050px, 50px)"
            mobileTransform="translate(140px, 190px)">
            <Connect />
          </SVGContainer>
        </Content>
        <CSSTransition
          in={index !== -1}
          timeout={1100}
          classNames="fade">
          <Background>
            <DetailLeftContainer />
            <DetailRightContainer />
            <CloseButton
              onClick={handleSVGContainer(-1)}>
              <Close />
            </CloseButton>
            <DetailContent show={index !== -1}>
              <DetailTitle>
                {name}
              </DetailTitle>
              <Detail>
                {description}
              </Detail>
            </DetailContent>
            <DetailButtonContainer show={index !== -1}>
              <DetailButton to="/technology/performance">
                시공실적 바로가기
              </DetailButton>
            </DetailButtonContainer>
          </Background>
        </CSSTransition>
        {isStartAnimation && (
          <LineContainer>
            <Line d="M 180,520 L 480,380" />
            <Line d="M 480,380 L 750,180" />
            <Line d="M 480,380 L 780,600" />
            <Line d="M 750,180 L 1030,380" />
            <Line d="M 780,600 L 1030,380" />
            <Line d="M 1030,380 L 1380,280" />
            <Line d="M 1030,380 L 1130,50" />
          </LineContainer>
        )}
      </ContentContainer>
    </ThirdContainer>
  )
}


export default ThirdComponent